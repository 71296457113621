































































































import {Component, Ref, Vue} from "vue-property-decorator";
import OfflineTimeslotPicker from "~/components/course/offline-timeslot-picker.vue";
import PageHeader from "~/components/app/page-header.vue";
import {Course} from "~/components/data-class/data-class";
import {ClassSchedule, LessonTimeSlot, OfflineClass, OfflineEvent, OfflineLesson} from '~/components/course/offline-course-model'
import OfflineClassBasicEditor from "~/components/course/offline-class-basic-editor.vue";
import {createRequest} from "~/utils/network-request";
import CourseData from "~/components/course/course-data";
import {toInstanceForce, toInstanceForceArray} from "~/utils/Serializer";
import {SubjectList} from "@afterschool.dev/as-data-admin";
import Dict = NodeJS.Dict;
import {ElTree} from "element-ui/types/tree";

@Component({
    components: {
        OfflineTimeslotPicker,
        PageHeader,
        OfflineClassBasicEditor
    },
    metaInfo() {
        return {
            title: 'Edit Offline Course'
        }
    }
})
export default class BatchOfflineClassEditor extends Vue {
    name: 'batch-offline-class-editor'

    // Edit.
    selectedCourseSubject: string = ''
    courseSubject = SubjectList.offered.map(sub => ({
        _id: sub._id,
        tag: sub.tag
    }))
    classTreeData = []
    @Ref() tree: ElTree<
    string,
    {
        id: {
            course_id: string,
            class_id: number,
        },
        label: string
    }>

    // Create.
    courseId = ''
    createAmount = 1
    classIds: number[] = [] // Class ids of all classes of the same course, no matter the version of the course that the class belongs to. It is needed to check that the current class' id was not used before.

    // Edit.
    editBasic = false
    editLinks = false

    course = new Course()
    offlineClass = new OfflineClass()

    async created() {
        if (!this.offlineClass.form[0]) this.offlineClass.form.push(1)
    }

    get isEdit() {
        return this.$route.path.includes('edit-class')
    }

    // Add.
    async courseChanged() {
        this.course = await CourseData.shouldGetCourse(this.courseId)
        const res = await createRequest('/course/' + this.courseId + '/class/classes', 'get').send()
        this.classIds = toInstanceForceArray(new OfflineClass(), res.data.classes).map((c) => c.id).sort((a, b) => a - b)
    }

    // Edit.
    async subjectChanged() {
        const res = await createRequest('/courses/batch-edit/class-list', 'get', {
            subject: this.selectedCourseSubject
        }).send()
        const classes: {
            classes: { course_id: string, id: number, schedule: string }[],
            id: number
        }[] = res.data.classes
        const courseTitles: Dict<string> = res.data.course_titles

        this.classTreeData = classes.map((e) => {
            return {
                id: e.id,
                label: `Class ${e.id}`,
                children: e.classes.map((c) => {
                    return {
                        id: {
                            course_id: c.course_id,
                            class_id: e.id,
                        },
                        label: `${c.course_id} - ${courseTitles[c.course_id]} **直播班**  ${c.schedule}`
                    }
                })
            }
        })

    }

    backClicked() {
        this.$confirm('將不會儲存更改，確認要返回？', '確認返回？', {
            confirmButtonText: '確認返回',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(async () => {
            await this.$router.push('/course/course-list')
        }).catch(async () => {

        })
    }

    async submitClicked() {

        if (this.isEdit && !this.editBasic && !this.editLinks) {
            this.$message.error('請選擇需要修改的部份')
            return
        }

        if (!this.isEdit || this.editBasic) {
            if (this.offlineClass.quota <= 0) {
                this.$message.error('班次名額不能少於或等於0')
                return
            }

            if (this.offlineClass.min_applied < 0) {
                this.$message.error('班次最少報讀人數不能少於0')
                return
            }

            if (this.offlineClass.min_applied > this.offlineClass.quota) {
                this.$message.error('班次 最少報讀人數 不能大於 班次名額')
                return
            }
        }

        // Trim input.
        this.offlineClass.whatsapp_link = this.offlineClass.whatsapp_link.trim()
        this.offlineClass.zoom_link = this.offlineClass.zoom_link.trim()

        // Map class details data to ClassSchedule.
        const body = Object.assign({}, this.offlineClass)
        if (!this.offlineClass.fixed_time) {
            delete body['time']
        }
        if (!this.offlineClass.fixed_length) {
            delete body['length']
        }

        // Remove redundant keys from object creation.
        for (const key of ['version', 'preserved', 'applied', 'paid', 'quota_percent', 'noti', 'start', 'end', '_id', 'course_id']) {
            delete body[key]
        }

        if (this.isEdit) {
            // Edit class.

            // Delete fields that cannot be batch edited.
            for (const key of ['id', 'title', 'fixed_time', 'fixed_length', 'time', 'length', 'lessons', 'events', 'schedule', 'renewal_time', 'formTemplate']) {
                delete body[key]
            }

            // Only update selected sections.
            const linksKeys: (keyof OfflineClass)[] = ['whatsapp_link', 'zoom_link']
            if (this.editBasic && !this.editLinks) {
              for (const key of Object.keys(body) as (keyof OfflineClass)[]) {
                if (linksKeys.includes(key)) {
                  delete body[key]
                }
              }
            }
            else if (!this.editBasic && this.editLinks) {
              for (const key of Object.keys(body) as (keyof OfflineClass)[]) {
                if (!linksKeys.includes(key)) {
                  delete body[key]
                }
              }
            }

            const checkedNodes = this.tree.getCheckedNodes(true);

            let allSuccessful = true
            await Promise.all(checkedNodes.map((node) => {
                const b = Object.assign({}, body)
                // b.id = node.class_id
                return createRequest('/course/' + node.id.course_id + '/class/' + node.id.class_id, 'patch', {}, b).send(true)
                    .then(() => {

                    })
                    .catch((e) => {
                        allSuccessful = false
                        this.$message.error(`Failed to create class ${b.id}`)
                    })
            }))
            if (allSuccessful) {
                this.$message.success('All classes updated successfully!')
            }

        } else {

            if (!this.courseId) {
                this.$message.error('必須選擇課程')
                return
            }

            if (this.offlineClass.id <= 0) {
                this.$message.error('班次ID不能少於或等於0')
                return
            }

            let allSuccessful = true

            const availableSlots = this.classIds.map((id, index, classIds) => {
                if (index === 0)
                    return id === 0 ? [] : Array(id - 1).fill(0).map((_, i) => i + 1)
                return Array(id - classIds[index - 1] - 1).fill(0).map((_, i) => i + classIds[index - 1] + 1)
            }).flat()

            for (const [i, _] of Array(this.createAmount).fill(0).entries()) {
                const b = Object.assign({}, body)
                b.id = availableSlots[i] ? availableSlots[i] : (this.classIds[this.classIds.length - 1] + (i + 1) - availableSlots.length)
                try {
                    await createRequest('/course/' + this.courseId + '/class', 'post', {}, b).send(true)
                }
                catch (e) {
                    allSuccessful = false
                    this.$message.error(`Failed to create class ${b.id}`)
                }
            }

            if (allSuccessful) {
                this.$message.success('All classes created successfully!')
            }

        }
    }
}

